import { Component } from '@angular/core';

@Component({
    selector: 'app-develop',
    templateUrl: './develop.component.html',
    styleUrls: ['./develop.component.css'],
})
export class DevelopComponent {
    constructor() {}
}
