<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-text-center no" id="results" *ngIf="datos">
  <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-text-center alert-primary">
    <span>
      <strong> Bienvenido(a)! </strong>  {{ nombreAlumno_ }} {{ rutAlumnoNumber }} 
    </span>
  </div>
</div>

<div class="p-grid" *ngIf="datos">
  <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">  
  </div>
  <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12" *ngIf ="habilita_arancel">  
      <tabla-cursos [cursos_aranceles]="aranceles_" [datos]="datos" [rutFin700]="rutAlumno_" [centroCosto]="centroCosto" [dv] = "dv" [rut] = "rut_"
      (enviarPadre)="actualizar($event)" [habilita_arancel]="habilita_arancel" [comprobantes]="comprobantes"></tabla-cursos>
  </div>
  <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-text-center">
    <span class="text-info">
      <i aria-hidden="true" class="fa fa-info-circle"></i>
      Cualquier duda o consulta, favor contactarse con
      <a href="mailto: finanzas.recaudacion@uv.cl">finanzas.recaudacion@uv.cl</a>
    </span>
  </div>
</div>