<div class="p-grid p-mt-2">
  <div class="p-d-col-none p-md-1 p-lg-1"></div>
  <div class="p-col-12 p-md-12 p-lg-10">
    <div class="p-grid">                                   
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
        <buscador></buscador>
      </div>
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
        <cursos></cursos>
      </div>
    </div>
  </div>
  <div class="p-d-col-none p-md-1 p-lg-1"></div>
</div>
