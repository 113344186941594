import { Component, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    ActivatedRoute,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UsuarioService } from './services/usuario.service';
import { ErroresHandler } from './tools/handler/error.handler';
import { PanelControlService } from './services/panel_control.service';
import { SystemService } from './services/system.service';
import { InitializationService } from './services/initialization.service';
import { StartService } from '../project/services/start.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.css'],
})
export class BaseComponent {
    config = this._config.get();
    user: any;
    title = this._config.get('SISTEMA.NOMBRE');
    breadCrumb = [];
    position = '';
    basePages = ['/error', '/session'];

    loading = {
        msgs: this._config.get('SISTEMA.NOMBRE'),
    };

    constructor(
        public elementRef: ElementRef,
        private _loading: NgxSpinnerService,
        private _config: InitializationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private usuarioService: UsuarioService,
        public translateService: TranslateService,
        private primengConfig: PrimeNGConfig,
        private erroresHandler: ErroresHandler,
        private messageService: MessageService,
        private panelControlService: PanelControlService,
        private systemService: SystemService,
        private titleService: Title,
        private startService: StartService
    ) { }

    async ngOnInit() {
        this.usuarioService.initGTAG(
            this.elementRef,
            this._config.get('MONITORING_TRACING.GOOGLE_ANALYTICS.ACTIVO')
        );

        this.primengConfig.ripple = true;

        // Observer: control de loading
        this.systemService.loading$.subscribe(async (res) => {
            let fg = await this.translateService.get(['loading']).toPromise();

            if (res['active']) {
                this.loading.msgs = res.hasOwnProperty('msgs')
                    ? res['msgs']
                    : fg['loading'];
                this._loading.show(undefined, {
                    bdColor: res.hasOwnProperty('bdColor')
                        ? res['bdColor']
                        : this._config.get('LOADING.bdColor'),
                    color: res.hasOwnProperty('color')
                        ? res['color']
                        : this._config.get('LOADING.color'),
                    type: 'ball-clip-rotate',
                    size: 'medium',
                    fullScreen: true,
                });
            } else this._loading.hide();
        });

        // Observer: mensaje de error (toast)
        this.erroresHandler.error$.subscribe((res) => {
            this.messageService.add(res);
        });

        // Observer: nombre de pagina
        this.panelControlService.panel$.subscribe((res) => {
            this.title =
                res['module'] != undefined
                    ? res['module'].nombre
                    : this._config.get('SISTEMA.NOMBRE');
            this.setTitlePage(this.position);
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((res: any) => {
                let extras = this.router.getCurrentNavigation()?.extras;
                (extras || {}).skipLocationChange = true;
                if (!this.basePages.includes(res.url)) {
                    let data = this.panelControlService._getBreadCrumb(
                        res.url,
                        extras.state || null
                    );
                    this.breadCrumb = data.breadCrumb;
                    this.position = data.position;
                    this.panelControlService.menusSubject.next({
                        menu: data.menu,
                        item: data.item,
                    });
                }

                this.setTitlePage(this.position);
            });
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
            )
            .subscribe(() => {
                let view = this.panelControlService._homeMenu();
                if (this.breadCrumb.length > 0)
                    view = this.breadCrumb[this.breadCrumb.length - 1].object;
                this.googleAnalytics(view.metodo, view.nombre);
            });

        this.translate();

        try {
            await this.get_session(window.location.href);
            await this.startService.startServices();
            this.systemService.homeNavigate();
        } catch (e) {
            this.panelControlService.menusSubject.next({
                menu: this.panelControlService._homeMenu(),
                item: null,
            });
            this.erroresHandler.processError(e.error, 'page', e.msgs);
        }
        this.systemService.offPreloader();
    }

    private async get_session(url) {
        try {
            if (this._config.get('SISTEMA.LOGIN')) {
                // USER ONLINE                
                if (window.sessionStorage.getItem('token') == null) {
                    window.sessionStorage.clear();
                    let uid = url.split('?uid=')[1];
                    if (uid != undefined && uid != null && uid != '') {

                        await this.usuarioService.get_user_redis({
                            idSesion: uid,
                        });
                        this.user = await this.usuarioService.get_user_online();
                        window.history.replaceState('', '', '');
                    } else {
                        window.location.replace(
                            this._config.get('SISTEMA.URL.PORTAL')
                        );
                    }
                }
            } else {
                // USER OFFLINE

                let uid = url.split('?uid=')[1];

                if (uid != null || uid != undefined) {

                    await this.usuarioService.get_user_redis({
                        idSesion: uid,
                    });
                }

                /*  if ((window.sessionStorage.getItem("rut") == null || window.sessionStorage.getItem("rut") == undefined) &&
                     (window.sessionStorage.getItem("cc") == null || window.sessionStorage.getItem("cc") == undefined) && 
                     (window.sessionStorage.getItem("dv") == null || window.sessionStorage.getItem("dv") == undefined)) {
                     //this.getUserRedis(uid_url);
                     let uid = url.split('?uid=')[1];
                     await this.usuarioService.get_user_redis({
                         idSesion: uid,
                     });
                 } */

                if (window.sessionStorage.getItem('user') == null) {
                    this.user = await this.usuarioService.getUserOffline();
                }
            }
            if (
                window.sessionStorage.getItem('token') != null ||
                window.sessionStorage.getItem('user') != null
            ) {
                this.user = JSON.parse(window.sessionStorage.getItem('user'));
            }

            let lang =
                this.user != null
                    ? this.user.idioma
                    : this._config.get('SISTEMA.TRANSLATE.DEFAULT');
            this.translateService.use(lang);
            this.translateService.get('primeng').subscribe((res) => {
                this.primengConfig.setTranslation(res);
            });
        } catch (e) {
            this.user = null;
            let fg = await this.translateService
                .get(['usuario.profile.error'])
                .toPromise();
            throw { error: e, msgs: fg['usuario.profile.error'] };
        }
    }

    private setTitlePage(position) {
        this.titleService.setTitle(
            this.title + (position != '' ? ' - ' + position : '')
        );
    }

    private translate() {
        this.translateService.setDefaultLang(
            this._config.get('SISTEMA.TRANSLATE.DEFAULT')
        );
        if (this._config.get('SISTEMA.TRANSLATE.ACTIVO')) {
            let tmp = [];
            this._config.get('SISTEMA.TRANSLATE.IDIOMAS').forEach((e) => {
                tmp.push(e.value);
            });
            this.translateService.addLangs(tmp);
        } else {
            this.translateService.addLangs([
                this._config.get('SISTEMA.TRANSLATE.DEFAULT'),
            ]);
        }
        this.translateService.use(
            this._config.get('SISTEMA.TRANSLATE.DEFAULT')
        );
    }

    private googleAnalytics(page, title) {
        this.usuarioService.setGTAG(
            this.elementRef,
            this._config.get('MONITORING_TRACING.GOOGLE_ANALYTICS.ACTIVO'),
            title,
            page
        );
    }
}
