import { Injectable } from '@angular/core';
import { RutDirective } from 'ng9-rut';
import { Subject } from 'rxjs';
import { InvocaService } from '../../base/services/invoca.service';

@Injectable({
    providedIn: 'root',
})
export class PagoCursosService {
    constructor(private invoca: InvocaService) { }

    buscadorS = new Subject<object>();
    buscador$ = this.buscadorS.asObservable();

    sinCurso: boolean;

    async getValidaCursos(rutFin700: any, codigo: any): Promise<any> {
        return await this.invoca.httpInvoke('getValidaCursos', {
            rut: rutFin700,
            codigo: codigo,
        });
    }
  
    async getPagosWebPay(rutFin700: any, centroCosto: any): Promise<any> {
        var data = await this.invoca.httpInvoke('getWebPayBoletasByRut', {
            rut: rutFin700,
            codigo: centroCosto
        });

        return data;
    }

    async getComprobantesWebPay(rutFin700: any, codigo: any): Promise<any> {
        var data = await this.invoca.httpInvoke('getComprobantesWebPay', {
            rut: rutFin700,
            codigo: codigo
        });

        return data;
    }

    //token,proyecto,modulo,itemsPagos, origen
    async creaRedisId(token: any,proyecto: any, modulo: any, itemsPagos: any[], origen: any, datosAlumno: any): Promise<any> {
        return await this.invoca.httpInvoke('creaRedisId', {
            token: token,
            proyecto: proyecto,
            modulo: modulo,
            itemsPagos: itemsPagos,
            origen: origen, 
            datosAlumno: datosAlumno
        });
    }

    async getComprobanteFin700WebPay(params?: any): Promise<any> {
        return await this.invoca.httpInvokeReport('getComprobantesWebPay', 'pdf', params);
      }
}
