<p-dialog
  [showHeader]="false"
  [(visible)]="showIdle"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closeOnEscape]="false"
  [dismissableMask]="false"
  [closable]="false"
>
  <div class="iconIdle margin-bottom text-center">
    <i class="fas fa-user-clock fa-4x" style="color: #0055ad"></i>
  </div>
  <div class="text-center margin-top">
    <h4>{{ "idle.titulo" | translate }}</h4>
    {{ "idle.body" | translate }}
  </div>
  <ng-template pTemplate="footer">
    <p-button
      [style]="{ width: '100%' }"
      (click)="reset()"
      label="{{ 'idle.button' | translate }}"
    ></p-button>
  </ng-template>
</p-dialog>
