<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12" *ngIf="size_aranceles > 0 && _habilita_arancel">

  <!-- LISTA DE ARANCELES POR PAGAR / PAGADOS -->
  <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
    <p-table #aranceles [value]="_cursos_aranceles" [responsive]="true">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          Aranceles pendientes de pago / pagados
          <p-button icon="pi pi-refresh" (click)="updateTable()" pTooltip="Actualizar"></p-button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="text-align: center">Nombre curso</th>
          <th style="text-align: center">Fecha de vencimiento</th>
          <th style="text-align: center">Cuota</th>
          <th style="text-align: center">Saldo a pagar</th>
          <th style="text-align: center">Saldo pagado</th>
          <th style="text-align: center">Estado de pago</th>
          <th style="text-align: center">Pagar</th>          
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cursos>
        <tr>
          <td style="text-align: left">{{ cursos.nombrePrograma }}</td>
          <td style="text-align: center">{{ cursos.fecVencimiento }}</td>
          <td style="text-align: center">{{ cursos.numCuota }}</td>
          <td style="text-align: center">{{ cursos.montoDeuda_clp }}</td> 
          <td style="text-align: center">{{ cursos.saldoPagado_clp }}</td> 
          <td style="text-align: center">         
            <span class="{{
            cursos.estadoPago == 'PAGADO'
              ? 'status-padding qualified'
              : 'status-padding unqualified'
          }} ">{{ cursos.estadoPago }}</span>
          </td>
          <td style="text-align: center">
            <span *ngIf="cursos.estadoPago == 'NO PAGADO'">
              <button type="button" pButton class="ui-button-success" (click)="pagarSaldo(cursos)" icon="pi pi-dollar"
                pTooltip="Pagar por Webpay"></button>
            </span>
            <span *ngIf="cursos.estadoPago == 'PAGADO'"> - </span>
          </td>          
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="7">
            Sin registros de arancel pagados / por pagar.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>


  <!-- LISTA DE COMPROBANTES -->
  <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">

    <p-table #comprobantes [value]="_comprobantes" [responsive]="true" dataKey="DATAKEY">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          Comprobantes webpay de aranceles pagados
          <p-button icon="pi pi-refresh" (click)="updateTableComprobantes()" pTooltip="Actualizar"></p-button>
        </div>        
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem"></th>
          <th style="text-align: center">Nombre curso</th>
          <th style="text-align: center">Monto</th>
          <th style="text-align: center">Orden de compra</th>
          <th style="text-align: center">Cód. autorización</th>
          <th style="text-align: center">Fecha</th>
          <th style="text-align: center">Tipo</th>
          <th style="text-align: center">Cuotas</th>
          <th style="text-align: center">Ver comprobante</th>          
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-comprobantes>
        <tr>
          <td>
            <button type="button" pButton pRipple [pRowToggler]="comprobantes"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td style="text-align: left">{{ comprobantes.NOMBRE_CARRERA }}</td>
          <td style="text-align: center">{{ comprobantes.MONTO_CLP }}</td>
          <td style="text-align: center">{{ comprobantes.ORDEN_COMPRA }}</td>
          <td style="text-align: center">{{ comprobantes.CODIGO_AUTORIZACION }}</td>
          <td style="text-align: center">{{ comprobantes.FECHA_STRING }}</td>
          <td style="text-align: center">{{ comprobantes.TIPO_PAGO }}</td>
          <td style="text-align: center">{{ comprobantes.CUOTAS }}</td>         
          <td style="text-align: center">

            <button type="button" pButton class="ui-button-success" (click)="getComprobante(comprobantes,'ver')"
              icon="pi pi-eye" pTooltip="Descargar comprobante"></button>

          </td>

        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-product>
        <tr style="background-color:#f9f4e7;">
          <td colspan="9">
            <div class="p-p-3">

              <p-table #detallePagos [value]="product.DETALLE" dataKey="DATAKEY">
                
                <ng-template pTemplate="caption">
                  <div class="p-d-flex p-ai-center p-jc-between">
                    Detalle pagos
                  </div>
                </ng-template>
                
                <ng-template pTemplate="header">
                  <tr>
                    <th style="text-align: center">Curso</th> 
                    <th style="text-align: center">Centro costo</th>                    
                    <th style="text-align: center">Monto deuda</th>                   
                    <th style="text-align: center">Periodo</th>
                    <th style="text-align: center">Cuota / Semestre</th>
                    <th style="text-align: center">Tipo deuda</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-order>
                  <tr>
                    <td style="text-align: left">{{order.NOMBRECARRERA}}</td>
                    <td style="text-align: center">{{order.CENTROCOSTO}}</td>                   
                    <td style="text-align: center">{{order.MONTOBOLETA_CLP}}</td>                    
                    <td style="text-align: center">{{order.PERIODO}}</td>
                    <td style="text-align: center">{{order.NUMCUOTA}}</td>
                    <td style="text-align: left">{{order.TIPODEUDA}}</td>                    
                  </tr>
                </ng-template>

              </p-table>

            </div>
          </td>
        </tr>

      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="8" style="text-align: center;">
            Sin comprobante(s) webpay de arancel(es) pagado(s).
          </td>
        </tr>
      </ng-template>

    </p-table>

  </div>

</div>

<!-- ********************* VISOR REPORTE  ************************ -->

<p-dialog header="Comprobante WebPay" [(visible)]="showFormularioDialog" [modal]="true" [resizable]="false"
  [draggable]="false" position="top" [style]="{ width: '80vw' }" [baseZIndex]="10000">
  <div id="visorPDF"></div>
  <ng-template pTemplate="footer">
    <div class="p-jc-end">
      <button pButton pRipple class="p-button-sm p-button-text p-button-secondary" label="Cerrar" icon="pi pi-times"
        (click)="showFormularioDialog = false"></button>
      <button pButton pRipple class="p-button-sm p-button-danger" label="Descargar" icon="pi pi-download"
        (click)="descargarComprobante()"></button>
    </div>
  </ng-template>
</p-dialog>