<footer class="sticky-footer">
  <div class="container">
    <div class="copyright p-text-center">
      <div>
        <a href="{{ config.UV.URL }}" target="_blank" class="normal_link">{{
          config.UV.NOMBRE
        }}</a>
        &reg; v{{ package.version }}<br />
        <a
          href="{{ config.DTIC.URL }}"
          target="_blank"
          class="normal_link"
          title="{{ config.DTIC.NOMBRE }}"
          >{{ config.DTIC.SIGLA }}</a
        >
        &copy;
        {{ fecha | date: "yyyy" }}
      </div>
    </div>
  </div>
</footer>
