import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { InitializationService } from '../../../services/initialization.service';
import { SystemService } from '../../../services/system.service';
import { UsuarioService } from '../../../services/usuario.service';
import { ErroresHandler } from '../../../tools/handler/error.handler';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
})
export class ProfileComponent {
    config = this._config.get();

    user: any;
    showDialog = false;

    constructor(
        private _config: InitializationService,
        public translateService: TranslateService,
        private messageService: MessageService,
        private systemService: SystemService,
        private usuarioService: UsuarioService,
        private erroresHandler: ErroresHandler
    ) {}

    combo_idiomas = [];
    idioma_selected = null;

    anonUser = !this._config.get('SISTEMA.LOGIN');

    async ngOnInit() {
        this.user = JSON.parse(window.sessionStorage.getItem('user'));
    }

    show_profile() {
        this.combo_idiomas = [];
        this.translateService.getLangs().forEach((e) => {
            this._config.get('SISTEMA.TRANSLATE.IDIOMAS').forEach((f) => {
                if (e == f.value)
                    this.combo_idiomas.push({ label: f.label, value: e });
            });
            if (e == this.translateService.currentLang)
                this.idioma_selected = e;
        });
        this.showDialog = true;
    }

    async change_idioma(event) {
        try {
            this.systemService.setLoading(true);
            this.translateService.use(event.value);
            let fg = await this.translateService
                .get(['idioma.summary', 'idioma.detail'])
                .toPromise();
            this.systemService.languajeSubject.next();
            this.messageService.add({
                severity: 'success',
                summary: fg['idioma.summary'],
                detail: fg['idioma.detail'],
                key: 'idioma',
            });
            let user = JSON.parse(window.sessionStorage.getItem('user'));
            user.idioma = event.value;
            window.sessionStorage.setItem('user', JSON.stringify(user));
            await this.usuarioService.saveIdioma(event.value);
            this.systemService.setLoading(false);
        } catch (e) {
            this.erroresHandler.processError(e, 'none');
        }
    }
}
