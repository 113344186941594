import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InvocaService } from './invoca.service';
import { Usuario } from '../interfaces/usuario';
import { InitializationService } from './initialization.service';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';

@Injectable()
export class UsuarioService {
    constructor(
        private _config: InitializationService,
        private invoke: InvocaService,
        private http: HttpClient,
        private location: Location
    ) { }

    /**
     * Obtiene el token de sesión de usuario desde redis
     * @param idSesion --> uid proveniente desde la URL
     */
    async get_user_redis(params?: any): Promise<any> {
     
        let session = await this.invoke.httpInvoke('base/getKeyUser', params);     
        window.sessionStorage.setItem('proyecto', session.proyecto);
        window.sessionStorage.setItem('modulo', session.modulo);
        window.sessionStorage.setItem('token', session.token);
        window.sessionStorage.setItem("menu", session.menu);
        window.sessionStorage.setItem("cc", session.params.cc);
        window.sessionStorage.setItem("rut", session.params.rut);
        window.sessionStorage.setItem("dv", session.params.dv);                                        
        this.location.replaceState('');
    }

    /**
     * Obtiene los datos "escenciales" del usuario desde el token de sesión, y lo mantiene en sessionStorage
     */
    async get_user_online(): Promise<any> {
        let r = await this.invoke.httpInvoke('base/getUser', null);

        let _name_profile = '';
        if (r.nombreCompleto != '') {
            let _nombre_navbar = r.nombreCompleto.split(' ');
            if (_nombre_navbar.length > 0) {
                let counter = 0;
                _nombre_navbar.forEach((e) => {
                    if (counter != 1) {
                        _name_profile = _name_profile + e + ' ';
                        counter++;
                    } else {
                        _name_profile = _name_profile + e + '<br/>';
                        counter = 0;
                    }
                });
            }
        }

        let ip = await this.get_ip(this._config.get('GET_IP.ACTIVE'));

        let user = <Usuario>{
            uid: r.rut.substring(0, r.rut.length - 2),
            rut: r.rut,
            nombres: r.nombres,
            apellidos: r.apellidos,
            nombre_completo: r.nombreCompleto,
            correo_uv: r.correouv,
            correo_personal: r.mail,
            foto:
                this._config.get('TUI.URL_FOTO') +
                r.foto +
                this._config.get('TUI.FORMATO_FOTO'),
            idioma: r.idioma,
            ip: ip,
        };

        window.sessionStorage.setItem('user', JSON.stringify(user));

        return user;
    }

    async getUserOffline(): Promise<any> {
        let ip = await this.get_ip(this._config.get('GET_IP.ACTIVE'));

        let user = {
            uid: 11111111,
            rut: '11111111-1',
            nombres: this._config.get('UV.NOMBRE'),
            apellidos: '',
            nombre_completo: this._config.get('UV.NOMBRE'),
            correo_uv: '',
            correo_personal: '',
            foto: `${this._config.get(
                'SISTEMA.URL.REPOSITORIO'
            )}imagenes/iconos_sistemas/user/user_uv.png`,
            idioma: this._config.get('SISTEMA.TRANSLATE.DEFAULT'),
            ip: ip,
        };

        window.sessionStorage.setItem('user', JSON.stringify(user));

        return user;
    }

    /**
     * Obtiene la IP publica y privada del usuario
     * publica == privada --> conexion externa
     * publica != privada --> conexion interna
     * @param active --> se obtendra o no la IP
     */
    async get_ip(active): Promise<any> {
        let out = { interna: false, publica: '', local: '' };
        if (active) {
            try {
                let publica = await this.http
                    .get(this._config.get('GET_IP.PUBLICA'), {
                        responseType: 'json',
                    })
                    .toPromise();
                let local;
                try {
                    local = await this.invoke.httpInvokeUrl(
                        this._config.get('GET_IP.LOCAL'),
                        {
                            publica: publica['ip'],
                        }
                    );
                } catch (f) {
                    local = await this.invoke.httpInvokeUrl(
                        this._config.get('GET_IP.LOCAL'),
                        {
                            publica: '',
                        }
                    );
                }
                out.local = local.local;
                out.interna = local.interna;

                return out;
            } catch (e) {
                return out;
            }
        } else return out;
    }

    initGTAG(elementRef, activated) {
        if (environment.production && activated) {
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://www.googletagmanager.com/gtag/js?id=${environment.analytics}`;
            elementRef.nativeElement.appendChild(s);
        }
    }

    setGTAG(elementRef, activated, title, page) {
        if (environment.production && activated) {
            var script = document.getElementById('g_analytics');
            if (script != null) script.remove();

            var s = document.createElement('script');
            s.id = 'g_analytics';
            s.type = 'text/javascript';

            var code = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${environment.analytics}', {
                    page_title: '${title}', 
                    page_path: '/${page}'}
                );
            `;

            s.append(code);
            elementRef.nativeElement.appendChild(s);
        }
    }

    async saveIdioma(idioma) {
        await this.invoke.httpInvoke('base/saveIdioma', {
            idioma: idioma,
        });
    }
}
