<p-toast baseZIndex="5000" key="contacto"></p-toast>
<p-confirmDialog
  header="{{ 'ayuda.contacto.titulo' | translate }}"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text p-button-secondary p-button-sm"
  acceptButtonStyleClass="p-button-sm"
>
</p-confirmDialog>

<p-dialog
  header="{{ 'ayuda.contacto.titulo' | translate }}"
  [baseZIndex]="10000"
  [(visible)]="showDialog"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [maximizable]="true"
  [draggable]="false"
  [resizable]="false"
  [position]="'top'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
>
  <form #contactForm="ngForm">
    <div class="p-grid">
      <div
        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 text-center text-muted"
      >
        <span [innerHTML]="'ayuda.contacto.subtitulo' | translate"></span>
      </div>
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 small text-muted">
        {{ "forms.fields" | translate }}
      </div>
      <ng-container *ngIf="anonUser">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          {{ "ayuda.contacto.nombre" | translate }} *
          <input
            pInputText
            type="text"
            name="nombre"
            #nombre="ngModel"
            [(ngModel)]="template.nombre"
            style="width: 100%"
            required
          />
          <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)">
            <small *ngIf="nombre.errors.required" class="p-error">{{
              "forms.required" | translate
            }}</small>
          </div>
        </div>
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          RUT *
          <small>({{ "forms.ejemplo" | translate }}: 12.345.678-9)</small>
          <input
            pInputText
            type="text"
            name="rut"
            #rut="ngModel"
            [(ngModel)]="template.rut"
            style="width: 100%"
            validateRut
            formatRut
            required
          />
          <div *ngIf="rut.invalid && (rut.dirty || rut.touched)">
            <small
              *ngIf="rut.errors.invalidRut || rut.errors.required"
              class="p-error"
              >{{ "forms.invalid" | translate }}</small
            >
          </div>
        </div>
      </ng-container>
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
        {{ "ayuda.contacto.correo" | translate }} *
        <input
          pInputText
          type="email"
          name="correo"
          #correo="ngModel"
          [(ngModel)]="template.correoUV"
          style="width: 100%"
          required
          email
        />
        <div *ngIf="correo.invalid && (correo.dirty || correo.touched)">
          <small *ngIf="correo.errors.required" class="p-error">{{
            "forms.required" | translate
          }}</small>
          <small *ngIf="correo.errors.email" class="p-error">{{
            "forms.invalid" | translate
          }}</small>
        </div>
      </div>
      <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
        {{ "ayuda.contacto.mensaje" | translate }} *
        <textarea
          pInputTextarea
          style="width: 100%; resize: none"
          name="body"
          rows="6"
          #body="ngModel"
          [(ngModel)]="template.body"
          required
        ></textarea>
        <div *ngIf="body.invalid && (body.dirty || body.touched)">
          <small *ngIf="body.errors.required" class="p-error">{{
            "forms.required" | translate
          }}</small>
        </div>
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <p-button
      label="{{ 'button.cerrar' | translate }}"
      icon="pi pi-times"
      styleClass="p-button-sm p-button-secondary p-button-text"
      (click)="showDialog = false"
    ></p-button>
    <p-button
      label="{{ 'button.enviar' | translate }}"
      icon="pi pi-envelope"
      styleClass="p-button-sm"
      (click)="contactForm.valid && sendContacto()"
      [disabled]="!contactForm.valid"
    ></p-button>
  </ng-template>
</p-dialog>
