<p-toast baseZIndex="5000" key="idioma"></p-toast>

<p-dialog
  header="{{ 'usuario.profile.titulo' | translate }}"
  [(visible)]="showDialog"
  [modal]="true"
  [style]="{ width: '30vw' }"
  [maximizable]="false"
  [draggable]="false"
  [resizable]="false"
  [position]="'top'"
  [baseZIndex]="1040"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
>
  <div class="p-grid">
    <div class="p-col-fixed foto_user" style="width: 180px">
      <img
        class="foto"
        src="{{ user?.foto }}"
        src-fallback="./assets/img/undraw_profile.svg"
      />
    </div>
    <div class="p-col">
      <h3>
        {{ user?.nombre_completo || "" }}
      </h3>
      <ng-container *ngIf="!anonUser">
        <em>{{ user?.rut }}</em
        ><br />
        {{ user?.correo_uv }}
      </ng-container>
      <div class="p-mt-3">
        {{ "usuario.profile.idioma" | translate }}<br />
        <p-dropdown
          (onChange)="change_idioma($event)"
          [style]="{ width: '100%' }"
          [options]="combo_idiomas"
          [(ngModel)]="idioma_selected"
          appendTo="body"
          [baseZIndex]="1050"
        >
          <ng-template let-item pTemplate="selectedItem">
            <div class="country-item country-item-value">
              <img
                src="{{ config.SISTEMA.URL.REPOSITORIO }}imagenes/idiomas/{{
                  idioma_selected.toLowerCase()
                }}.png"
                [class]="'flag flag-' + idioma_selected.toLowerCase()"
              />
              <div>{{ idioma_selected.toUpperCase() }}</div>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="country-item">
              <img
                src="{{ config.SISTEMA.URL.REPOSITORIO }}imagenes/idiomas/{{
                  item.label.toLowerCase()
                }}.png"
                [class]="'flag flag-' + item.value.toLowerCase()"
              />
              <div>{{ item.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      label="{{ 'button.cerrar' | translate }}"
      icon="pi pi-times"
      styleClass="p-button-sm p-button-secondary p-button-text"
      (click)="showDialog = false"
    ></p-button>
  </ng-template>
</p-dialog>
