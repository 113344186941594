import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import baseRoutes from './routing.base';
import appRoutes from '../../project/routing/routing.project';

for (let i = 0; i < baseRoutes.length; i++) {
    let e = baseRoutes[i];
    appRoutes.push(e);
}

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { initialNavigation: 'disabled' }),
    ],
    exports: [RouterModule],
})
export class RoutingModule {}
