import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ErroresHandler } from './tools/handler/error.handler';
import { Router } from '@angular/router';

// Locales
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
registerLocaleData(localeEs);
registerLocaleData(localeEn);

//Modules
import { ProjectModule } from '../project/project.module';
import { RoutingModule } from '../base/routing/routing.module';

//Services
import { UsuarioService } from './services/usuario.service';
import { InvocaService } from './services/invoca.service';
import { PortalService } from './services/portal.service';
import { PanelControlService } from './services/panel_control.service';
import { SystemService } from './services/system.service';
import { InitializationService } from './services/initialization.service';
import { NgxSpinnerService } from 'ngx-spinner';

// SENTRY
import { TraceService } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

//Traductor
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

//Pipes
import { NgxDatePipe } from './tools/pipes/ngx-date.pipe';
import { ChileMoneyPipe } from './tools/pipes/chile_money.pipe';
import { RutPipe } from './tools/pipes/rut.pipe';
import { SafePipe } from './tools/pipes/safe.pipe';
import { TruncateStringPipe } from './tools/pipes/truncate_string.pipe';

//Prime
import { MessageService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToolbarModule } from 'primeng/toolbar';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollTopModule } from 'primeng/scrolltop';

// Others
import { Ng9RutModule } from 'ng9-rut';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { NgIdleModule } from '@ng-idle/core';
import * as Sentry from '@sentry/angular';

//Components
import { BaseComponent } from './base.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactoComponent } from './components/header/contacto/contacto.component';
import { AvisosComponent } from './components/header/avisos/avisos.component';
import { ModuloAplicacionComponent } from './components/modulo-aplicacion/modulo-aplicacion.component';
import { SimbologiaComponent } from './components/header/simbologia/simbologia.component';
import { ProfileComponent } from './components/header/profile/profile.component';
import { MenusComponent } from './components/menus/menus.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { SessionComponent } from './components/pages/session/session.component';
import { MaintenanceComponent } from './components/pages/maintenance/maintenance.component';
import { IdleHandlerComponent } from './tools/handler/idle-handler/idle-handler.component';
import { DevelopComponent } from './components/pages/develop/develop.component';

Sentry.init({
    dsn: environment.sentry.dns,
    release: 'production',
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: environment.sentry.tracingOrigins,
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],
    tracesSampleRate: 1.0,
});

@NgModule({
    declarations: [
        //Pipes
        NgxDatePipe,
        ChileMoneyPipe,
        RutPipe,
        SafePipe,
        TruncateStringPipe,
        //Components
        BaseComponent,
        HeaderComponent,
        FooterComponent,
        ContactoComponent,
        AvisosComponent,
        SimbologiaComponent,
        ProfileComponent,
        MenusComponent,
        ModuloAplicacionComponent,
        ErrorComponent,
        NotFoundComponent,
        SessionComponent,
        MaintenanceComponent,
        IdleHandlerComponent,
        DevelopComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        ProjectModule,
        HttpClientModule,
        FormsModule,
        // Translate
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        // Others
        Ng9RutModule,
        NgxSpinnerModule,
        NgIdleModule.forRoot(),
        // Prime
        InputNumberModule,
        AvatarModule,
        OverlayPanelModule,
        DividerModule,
        ToolbarModule,
        SidebarModule,
        DropdownModule,
        BreadcrumbModule,
        TabViewModule,
        ToastModule,
        ButtonModule,
        RippleModule,
        ConfirmDialogModule,
        TabMenuModule,
        TableModule,
        DialogModule,
        AccordionModule,
        InputTextModule,
        InputTextareaModule,
        CheckboxModule,
        MenuModule,
        PanelModule,
        ProgressSpinnerModule,
        ScrollTopModule,
        //Routing Mantenerlo al final de la lista
        RoutingModule
    ],
    providers: [
        NgxDatePipe,
        UsuarioService,
        InvocaService,
        MessageService,
        PortalService,
        PanelControlService,
        SystemService,
        {
            provide: ErrorHandler,
            useClass: ErroresHandler,
        },
        InitializationService,
        {
            provide: APP_INITIALIZER,
            useFactory: (config: InitializationService) => () => config.load(),
            deps: [InitializationService, TraceService],
            multi: true,
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        NgxSpinnerService,
        ChileMoneyPipe,
    ],
    bootstrap: [BaseComponent],
})
export class BaseModule {}

//Translate
export function createTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/base/', suffix: '.json' },
        { prefix: './assets/i18n/project/', suffix: '.json' },
    ]);
}
