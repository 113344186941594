import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SystemService } from 'src/app/base/services/system.service';
import { ErroresHandler } from 'src/app/base/tools/handler/error.handler';
import { PagoCursosService } from '../../../../services/pago-cursos.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalReportesComponent } from '../../modal-reportes/modal-reportes.component';
import { Subscription } from 'rxjs';
import { ReportesService } from '../../../../services/reportes.service';

@Component({
    selector: 'tabla-cursos',
    templateUrl: './tabla-cursos.component.html',
    styleUrls: ['./tabla-cursos.component.css'],
    providers: [DialogService],
})
export class TablaCursosComponent {
    constructor(
        private erroresHandler: ErroresHandler,
        private systemService: SystemService,
        private pagoCursosService: PagoCursosService,
        public dialogService: DialogService,
        private reportesService: ReportesService,
    ) { }

    _cursos_matriculas: any;
    _cursos_aranceles: any;
    size_matriculados: any = 0;
    size_aranceles: any = 0;
    _listas: any = [];
    urlWebPay: string = '';
    modalReportesRef = null;
    _habilita_arancel: any
    _comprobantes: any;
    showFormularioDialog: boolean = false;
    report: any;

    @Output() enviarPadre = new EventEmitter();

    @Input() set cursos_matriculas(value: any) {
        this._cursos_matriculas = value;
        this.size_matriculados = this._cursos_matriculas.length;
    }

    @Input() set cursos_aranceles(value: any) {

        this._cursos_aranceles = value;
        this.size_aranceles = this._cursos_aranceles.length;
    }

    @Input() set comprobantes(value: any) {

        this._comprobantes = value;
    }

    @Input() datos: boolean;

    @Input() rutFin700: string;

    @Input() rut: string;

    @Input() centroCosto: string;

    @Input() dv: string;

    @Input() set habilita_arancel(value: any) {
        this._habilita_arancel = value;
    }

    sub1: Subscription;

    async ngOnInit() {

        this.pagoCursosService.buscador$.subscribe(async (e: any) => {
        });
    }

    async pagarSaldo(curso: any) {

        try {
            let cuotaSeleccionada = [];

            cuotaSeleccionada.push({

                abonoMax: curso.abonoMax,
                montoAbonoMaxCLP: curso.abonoMax_clp,
                bancoSantander: curso.bancoSantander,
                centroCosto: curso.centroCosto,
                deudaTotal: curso.deudaTotal,
                docCceNumero: curso.docCceNumero,              
                fecEmision: curso.fecEmision,
                fecVencimiento: curso.fecVencimiento,
                item: curso.item,
                monedaId: curso.monedaId,
                montoBoleta: curso.montoBoleta,
                deudaTotalFormatoVista: (curso.montoBoleta_clp).slice(1),
                montoMulta: curso.montoMulta,
                mostrarAbono: false,
                nombre: curso.nombre,
                nombrePrograma: curso.nombrePrograma,
                numCuota: curso.numCuota,
                numCuotaVista: curso.numCuotaVista,
                periodo: curso.periodo,
                rut: curso.rut,
                tdoid: curso.tdoid,
                tipoOpeId: curso.tipoOpeId
            })

            let datosAlumno = {
                rut: this.rut,
                dv: this.dv,
                centroCosto: this.centroCosto,
            }

            if ((curso.item).toUpperCase() == 'ARANCEL CURSOS') {
               
                let token = null;
                let proyecto = null;
                let modulo = null;
                let itemsPagos = cuotaSeleccionada;
                let origen = 'Aranceles'

                this.systemService.setLoading(true);
                let _idredis = await this.pagoCursosService.creaRedisId(token, proyecto, modulo, itemsPagos, origen, datosAlumno);

                window.sessionStorage.clear();
                window.open(_idredis.url, '_self');
            }

        } catch (e) {
            this.erroresHandler.processError(
                e,
                'alert',
                'Hubo un error en webpay',
                'Pago cursos: pendientes'
            );
        }      
    }
       
    updateTable() {
        this.enviarPadre.emit({ rutFin700: this.rutFin700 , tipo: "aranceles"});
    }

    updateTableComprobantes() {             
        this.enviarPadre.emit({ rutFin700: this.rutFin700 , tipo: "comprobantes"});
    }

    async getComprobante(comprobante: any, accion: string) {
              
        let param =
            "P_CODIGO_AUTORIZACION=" + comprobante.CODIGO_AUTORIZACION + "&" +
            "P_NUMERO_DOCUMENTO=" + comprobante.ORDEN_COMPRA + ""

        let dns = 'http://madlerreportes.uv.cl:8080';
        let reportUnit = '%2FFINCON%2FARANCEL%2FReports%2FcomprobantePagoWPFin700_V2';
        let nombreArchivo = 'Comprobante de pago';
        
        this.systemService.setLoading(true);

        this.report = await this.reportesService.getReporteComprobante({'param': param, 'reportUnit': reportUnit, 'dns': dns});   
               
        this.showFormularioDialog = true;

        setTimeout(() => {
            this.systemService.visorPDF('visorPDF', this.report,'600px', '100%');
        }, 100);
                     
        this.systemService.setLoading(false); 

    }

    async descargarComprobante(){
        try {           
            this.systemService.downloadReport(this.report, `ComprobanteWebPay-${this._comprobantes[0].NOMBRE}_${this.rutFin700}.pdf`)
        } catch (e) {
            this.erroresHandler.processError(
                e,
                'page',
                e.message,
                'Error al obtener el comprobante de pago webpay.'
            );
        }
    }

}


