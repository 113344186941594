<form [formGroup]="captchaForm">
  <ngx-recaptcha2
    #captchaElem
    [siteKey]="captchaData.key"
    [hl]="'es'"
    formControlName="recaptcha"
  >
  </ngx-recaptcha2>
  <div *ngIf="captchaForm.controls.recaptcha.touched || captchaData.error">
    <small class="p-error">
      <span *ngIf="captchaData.error">{{ "forms.required" | translate }}</span>
    </small>
  </div>
</form>
