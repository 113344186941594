import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ErrorD } from '../../interfaces/error';
import * as url from 'url';
import { InitializationService } from '../../services/initialization.service';
import { environment } from '../../../../environments/environment';
import { SystemService } from '../../services/system.service';

@Injectable({
    providedIn: `root`,
})
export class ErroresHandler implements ErrorHandler {
    constructor(
        private injector: Injector,
        private ngZone: NgZone,
        private _config: InitializationService,
        private systemService: SystemService
    ) {}

    private errorSubject = new Subject<object>();
    error$ = this.errorSubject.asObservable();

    async handleError(error: any): Promise<void> {
        this.systemService.setLoading(false);
        if (!environment.production) console.log(error);
        const router = this.injector.get(Router);

        let err: ErrorD;
        if (!(error instanceof ErrorD)) err = this.verifyIntegrity(error);
        else err = error;

        this.ngZone.run(() => {
            router
                .navigate([err.getContext().token ? '/session' : '/error'], {
                    state: err.getContext(),
                })
                .then();
        });
    }

    processError(error, sendMethod, messageShow?, summary?) {
        this.systemService.setLoading(false);
        let err: ErrorD = this.verifyIntegrity(error);
        if (!err.getContext().token) {
            let msgsCustom =
                typeof messageShow === 'string' ? messageShow : null;
            switch (sendMethod) {
                case 'page':
                    err.setMessageCustom(
                        msgsCustom != null ? msgsCustom : err.getError().message
                    );
                    if (err.hasOwnProperty('navigation'))
                        this.errorSubject.next({ navigation: true });
                    this.handleError(err);
                    break;
                case 'alert':
                    let toast = {
                        severity: 'error',
                        key: 'main',
                        sticky: true,
                        detail:
                            msgsCustom != null
                                ? msgsCustom
                                : 'Ha ocurrido un error. Intente nuevamente.',
                    };
                    if (summary) toast['summary'] = summary;
                    this.errorSubject.next(toast);
                    break;
            }
            if (
                this._config.get('MONITORING_TRACING.HANDLE_ERROR.CONSOLE_LOG')
            ) {
                this.showLog(err);
            }
        } else {
            this.errorSubject.next({ token: true });
            this.handleError(error);
        }
    }

    private verifyIntegrity(error) {
        let err: ErrorD;
        if (!(error instanceof ErrorD)) {
            let param = 'Uncaught (in promise): ErrorD:';
            err = new ErrorD(
                error,
                null,
                null,
                url.parse(this.injector.get(Router).url).pathname
            );
            try {
                if (error.message.includes(param)) {
                    let tmp = JSON.parse(
                        error.message.split(param)[1].trim()
                    ).context;
                    err.setContext(
                        tmp.path,
                        tmp.nombre,
                        tmp.nivel,
                        tmp.tipo,
                        tmp.servicio,
                        tmp.estado,
                        tmp.trace,
                        tmp.token
                    );
                    err.setMessageCustom(tmp.message);
                }
            } catch (e) {}
        } else err = error;
        return err;
    }

    private showLog(err) {
        let errContext = err.getContext();
        console.error(
            'status:',
            errContext.estado,
            '\npath:',
            errContext.path,
            '\ntype:',
            errContext.tipo,
            '\nmessage:',
            err.getError().message,
            '\nerror:',
            err.getError()
        );
    }
}
