import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanelControlService } from '../../services/panel_control.service';
import { ErroresHandler } from '../../tools/handler/error.handler';
import { InitializationService } from '../../services/initialization.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-menus',
    templateUrl: './menus.component.html',
    styleUrls: ['./menus.component.css'],
})
export class MenusComponent implements OnInit {
    constructor(
        private _config: InitializationService,
        private panelControlService: PanelControlService,
        private erroresHandler: ErroresHandler,
        private router: Router,
        private translateService: TranslateService
    ) {}

    menus = [];
    menu_selected: any;
    item_selected: any;
    show: boolean = false;
    @Input() title = '';
    config = this._config.get();

    async ngOnInit() {
        this.panelControlService.menus$.subscribe((res: any) => {
            this.menu_selected = res.menu;
            this.item_selected = res.item;
        });
        await this.getMenus();
    }

    do_show() {
        this.show = !this.show;
    }

    goMenuItem(menu, item) {
        this.panelControlService.navigateMenu(this.router, menu, item);
        this.menu_selected = menu;
        this.item_selected = item;
        this.show = false;
    }

    private async getMenus() {
        try {
            if (
                this._config.get('SISTEMA.LOADS.MENUS.DATA') &&
                this._config.get('SISTEMA.LOGIN')
            ) {
                this.menus = await this.panelControlService.get_menus({
                    codModulo: window.sessionStorage.getItem('modulo'),
                    metodo_activo: this._config.get(
                        'SISTEMA.MENUS.METODO_ACTIVO'
                    ),
                });
            }
        } catch (e) {
            let fg = await this.translateService
                .get(['menus.error'])
                .toPromise();
            e['navigation'] = true;
            this.erroresHandler.processError(e, 'page', fg['menus.error']);
        }
    }
}
