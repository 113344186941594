import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PortalService } from 'src/app/base/services/portal.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.css'],
})
export class MaintenanceComponent {
    mode = '';
    constructor(private router: Router, private portalService: PortalService) {
        this.mode = this.router.getCurrentNavigation().extras.state.mode;
    }

    async goBackPortal() {
        await this.portalService.backToPortal({
            proyecto: window.sessionStorage.getItem('proyecto'),
        });
    }
}
