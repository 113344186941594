import { Injectable } from '@angular/core';

@Injectable()
export class DateUtils {
    constructor() {}

    getDateToJSON(fecha) {
        var r = {
            date:
                '' +
                (fecha.getUTCDate() < 10
                    ? '0' + fecha.getUTCDate()
                    : fecha.getUTCDate()),
            month:
                '' +
                (fecha.getUTCMonth() + 1 < 10
                    ? '0' + (fecha.getUTCMonth() + 1)
                    : fecha.getUTCMonth() + 1),
            year: '' + fecha.getFullYear(),
        };
        return r;
    }

    getDateToNumber(fecha) {
        var r = {
            date:
                '' +
                (fecha.getUTCDate() < 10
                    ? '0' + fecha.getUTCDate()
                    : fecha.getUTCDate()),
            month:
                '' +
                (fecha.getUTCMonth() + 1 < 10
                    ? '0' + (fecha.getUTCMonth() + 1)
                    : fecha.getUTCMonth() + 1),
            year: '' + fecha.getFullYear(),
        };
        var out = parseInt(r.year + '' + r.month + '' + r.date);
        return out;
    }

    getJSONToDate(fecha) {
        var r = fecha.hasOwnProperty('date')
            ? new Date(
                  parseInt(fecha.year),
                  parseInt(fecha.month) - 1,
                  parseInt(fecha.date),
                  0,
                  0,
                  0,
                  0
              )
            : null;
        return r;
    }

    getDateToJSON_FULL(fecha) {
        var r = {
            date:
                '' +
                (fecha.getUTCDate() < 10
                    ? '0' + fecha.getUTCDate()
                    : fecha.getUTCDate()),
            month:
                '' +
                (fecha.getUTCMonth() + 1 < 10
                    ? '0' + (fecha.getUTCMonth() + 1)
                    : fecha.getUTCMonth() + 1),
            year: '' + fecha.getFullYear(),
            hour: '' + fecha.getUTCHours(),
            minute: '' + fecha.getUTCMinutes(),
            second: '' + fecha.getUTCSeconds(),
        };
        return r;
    }

    getJSONToDate_FULL(fecha) {
        var r = fecha.hasOwnProperty('date')
            ? new Date(
                  parseInt(fecha.year),
                  parseInt(fecha.month) - 1,
                  parseInt(fecha.date),
                  parseInt(fecha.hour),
                  parseInt(fecha.minute),
                  parseInt(fecha.second),
                  0
              )
            : null;
        return r;
    }

    getNumberToJSON(fecha) {
        var sFecha = fecha.toString();

        var r = {
            date: sFecha.substr(6, 2),
            month: sFecha.substr(4, 2),
            year: sFecha.substr(0, 4),
        };
        return r;
    }

    getNumberToJSON_DMY(fecha) {
        var sFecha = '' + fecha;

        var r = {
            date: sFecha.substr(0, 2),
            month: sFecha.substr(2, 2),
            year: sFecha.substr(4, 4),
        };

        return r;
    }

    parseJSONtoDate(fechaJSON) {
        return fechaJSON.hasOwnProperty('date')
            ? new Date(
                  fechaJSON.year,
                  fechaJSON.month - 1,
                  fechaJSON.date,
                  0,
                  0,
                  0,
                  0
              )
            : null;
    }

    parseJSONtoNumber(fechaJSON, formato) {
        var fecha = '';

        if (formato == 'DMY') {
            fecha =
                '' +
                fechaJSON.date +
                '' +
                fechaJSON.month +
                '' +
                fechaJSON.year;
        } else if (formato == 'YMD') {
            fecha =
                '' +
                fechaJSON.year +
                '' +
                fechaJSON.month +
                '' +
                fechaJSON.date;
        }

        return fecha;
    }
}
