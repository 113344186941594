/**
 * escribe aqui tus variables globales
 */

export const project_config = {
    MONITORING_TRACING: {
        GOOGLE_ANALYTICS: {
            ACTIVO: true,
        },
        SENTRY: {
            ACTIVO: true,
        },
        HANDLE_ERROR: {
            CONSOLE_LOG: true,
        },
    },
    IDLE: {
        ACTIVO: false,
        TIEMPO_SESION: 900,
        TIEMPO_AVISO: 180,
    },
    SISTEMA: {
        NOMBRE: "Pago cursos",
        LINKS: {
            AYUDA: {
                BUTTONS: {
                    PASO_A_PASO: false,
                    SIMBOLOGIA: false,
                },
            },
            USUARIO: {
                ACTIVO: false
            },
        },
        LOADS: {
            APPS: { BUTTON: false, DATA: false },
            MODULOS: { BUTTON: false, DATA: false },
            MENUS: { BUTTON: false, DATA: false },
            NOTICIAS: { BUTTON: false, DATA: false },
            AVISOS: { BUTTON: false, DATA: false },
            USUARIO: { DATA: false },
            VOLVER_PORTAL: { BUTTON: false },
            FECHA: { DATA: false },
        },
        LOGIN: false,
        LANDING: '',
        MENUS: {
            ITEMS_ICON: true,
            FLAT_ICONS: false,
            METODO_ACTIVO: '',
        },
        URL: {
            PATHMENUSIMG: 'imagenes/iconos_sistemas/menus/pagoCursos/',
            PATHITEMSIMG: 'imagenes/iconos_sistemas/menus/items/pagoCursos/',
            PATHSTATIC: '/static/sistemas/pagoCursos/',
        },
    },
};
