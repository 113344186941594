<p-toast baseZIndex="5000" key="main"></p-toast>
<ngx-spinner>
  <div class="p-text-center" style="margin-top: 30px">
    <p [innerHTML]="loading.msgs"></p>
  </div>
</ngx-spinner>
<app-idle-handler></app-idle-handler>

<app-header *ngIf="user"></app-header>
<div id="content">
  <div class="container-fluid container-body">
    <p-breadcrumb
      [model]="breadCrumb"
      [home]="{ icon: 'pi pi-home', object: null }"
      *ngIf="breadCrumb.length > 0 && config.SISTEMA.BREADCRUMB"
      (onItemClick)="systemService.homeNavigate($event)"
    ></p-breadcrumb>
    <router-outlet></router-outlet>
    <p-scrollTop></p-scrollTop>
  </div>
  <app-footer></app-footer>
</div>

<div class="preloader p-d-flex p-jc-center">
  <div class="p-as-center p-text-center">
    <p-progressSpinner
      [style]="{ width: '80px', height: '80px' }"
    ></p-progressSpinner>
    <div class="p-mt-3">
      {{ config.SISTEMA.NOMBRE }}
    </div>
  </div>
</div>
