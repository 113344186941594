import { Component } from '@angular/core';
import { ErroresHandler } from '../../../../base/tools/handler/error.handler';
import { SystemService } from '../../../../base/services/system.service';
import { PagoCursosService } from '../../../services/pago-cursos.service';
import { StartService } from '../../../services/start.service';
import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';
import { MessageService } from 'primeng/api';
import { ChileMoneyPipe } from '../../../../base/tools/pipes/chile_money.pipe';


@Component({
    selector: 'cursos',
    templateUrl: './cursos.component.html',
    styleUrls: ['./cursos.component.css'],
})
export class CursosComponent {
    constructor(
        private erroresHandler: ErroresHandler,
        private systemService: SystemService,
        private pagoCursosService: PagoCursosService,
        private startService: StartService,
        private messageService: MessageService,
        private chileMoneyPipe: ChileMoneyPipe
    ) { }

    matriculas: any;
    aranceles: any;
    aranceles_: any;
    listas = {};
    datos: boolean = false;
    nombreAlumno: string = '';
    nombreAlumno_: string = '';
    rutAlumno: string = '';
    rutAlumno_: string = '';
    rutAlumnoNumber: any;
    centroCosto: any;
    centro: any;
    sinCurso: any;
    habilita_arancel: boolean = false;
    rut: number = 0;
    dv: string = '';
    rut_: string = '';
    comprobantes: any;
    
    async ngOnInit() {

        this.pagoCursosService.buscador$.subscribe(async (e: any) => {
        
            this.sinCurso = e.sinCurso;
            this.datos= e.datos;
            this.centro = e.codigo;

            if (this.sinCurso == false) {
                this.rut = e.rutFin700;       
                this.centroCosto = e.codigo;
                this.dv = e.dv;
                this.rut_ = e.rut;                
                this.getPagosWebPay(e.rutFin700, e.codigo);
                this.getComprobantesWebPay(e.rutFin700, e.codigo);
                
            } else {
                this.datos = false;
            }
        });
    }

    actualizar(event: string) {
            
        if (event['tipo'] == 'aranceles') {
            this.getPagosWebPay(event['rutFin700'], this.centro);
        } else {            
            this.getComprobantesWebPay(event['rutFin700'], this.centro);
        }
                
    }

    async getPagosWebPay(rutFin700: string, codigo: any) {
               
        this.rutAlumno = rutFin700;
        this.centroCosto = codigo;
        this.rutAlumnoNumber = this.rutAlumno.replace(/^(0+)/g, '');
        
        try {

            if (this.rutAlumno != '') {

                this.systemService.setLoading(true);
                var output = await this.pagoCursosService.getPagosWebPay(
                    rutFin700, codigo
                );
              
                this.aranceles_ = output;
              
                if (this.aranceles_ != null || this.aranceles_.length > 0) {
                                      
                    this.habilita_arancel = true;
                    this.nombreAlumno_ = this.aranceles_[0].nombre;
                    this.rutAlumno_ = this.aranceles_[0].rut;
                    this.datos = true;

                    this.startService.scrollTo(`results`);

                }
                
                this.systemService.setLoading(false);

            }

        } catch (e) {
            this.erroresHandler.processError(
                e,
                'alert',
                'Hubo un error al obtener los pagos.',
                'Pago cursos: pendientes'
            );
        }

    }

    async getComprobantesWebPay(rutFin700: string, codigo: any) {
      
        this.rutAlumno = rutFin700;

        try {

            if (this.rutAlumno != '') {

                this.systemService.setLoading(true);
                var output = await this.pagoCursosService.getComprobantesWebPay(
                    rutFin700, codigo
                );
              
                this.comprobantes = output;
                                
                this.startService.scrollTo(`results`);

                this.systemService.setLoading(false);

            }

        } catch (e) {
            this.erroresHandler.processError(
                e,
                'alert',
                'Hubo un error al obtener los comprobantes webpay.',
                'Comprobantes pago webpay: pendientes'
            );
        }

    }

}
