import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PanelControlService } from './panel_control.service';
import { Router } from '@angular/router';
import { InitializationService } from '../../base/services/initialization.service';

declare var $: any;

@Injectable()
export class SystemService {
    constructor(
        private panelControlService: PanelControlService,
        private config: InitializationService,
        private router: Router
    ) {}

    private loadingSubject = new Subject<object>();
    loading$ = this.loadingSubject.asObservable();

    languajeSubject = new Subject<object>();
    languaje$ = this.languajeSubject.asObservable();

    offPreloader() {
        $('.preloader')
            .delay(1000)
            .fadeOut('slow', function () {
                $(this).remove();
            });
    }

    homeNavigate(event?) {
        if (event == undefined || event.item.object == null) {
            let home = this.panelControlService._homeMenu();
            if (this.config.get('SISTEMA.LANDING') === '') {
                this.panelControlService.menusSubject.next({
                    menu: home,
                    item: null,
                });
                this.router.navigate([`/${home.metodo}`]);
            } else {
                this.panelControlService.menuHome$.subscribe((r: any) => {
                    let find = r.menus.find(
                        (e) => e.metodo === this.config.get('SISTEMA.LANDING')
                    );
                    this.router.navigate([
                        find ? `/${find.metodo}` : `/${home.metodo}`,
                    ]);
                });
            }
        }
    }

    setLoading(active, options?) {
        let object = options != null ? options : {};
        object['active'] = active;
        this.loadingSubject.next(object);
    }

    downloadReport(report: Blob, filename: string) {
        let dwldLink = document.createElement('a');
        let url = URL.createObjectURL(report);

        let isSafariBrowser =
            navigator.userAgent.indexOf('Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1;

        if (isSafariBrowser) {
            dwldLink.setAttribute('target', '_blank');
        }

        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    visorPDF(id, src, height, width) {
        var s = document.createElement('iframe');
        s.src = URL.createObjectURL(src) + "#toolbar=0&view=FitH";
        s.height = height;
        s.width = width;

        var div = document.createElement('div');
     
        let container = document.getElementById(id);
        container.innerHTML = null;
        container.appendChild(div);
        container.appendChild(s);
    }

    filtering(array, paramFilter, paramCompare) {
        let tmp = array;
        if (paramFilter != '') {
            tmp =
                array.filter((e) => {
                    let a = e[paramCompare]
                        .trim()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toUpperCase();
                    let b = paramFilter
                        .trim()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toUpperCase();
                    if (a.includes(b)) return e;
                }) || [];
        }
        return tmp;
    }
}
