<div class="p-d-flex p-jc-center">
  <div class="p-mr-2">
    <p-panel header="Búsqueda" [toggleable]="false">
      <form #busquedaForm="ngForm">
        <div class="p-fluid p-grid">
          <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6">
            <label>Rut *</label>
            <div class="p-inputgroup">
              <input
                type="text"
                pInputText
                [(ngModel)]="busqueda.rut"
                min="0"
                max="9999999999"
                name="rutInput"
                #rutInput="ngModel"
                class="form-control"
                pattern="[0-9]*"
                style="width: 150px; flex: none"
                (change)="calculateDV()"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                required
              />
              <span class="p-inputgroup-addon">-</span>
              <input
                type="text"
                pInputText
                [(ngModel)]="busqueda.dv"
                maxlength="1"
                name="dvInput"
                #dvInput="ngModel"
                class="form-control p-text-center"
                style="width: 40px; flex: none"
                (change)="calculateDV()"
                required
              />
            </div>

            <div
              *ngIf="(rutInput.invalid || dvInput.invalid) && (rutInput.dirty || rutInput.touched || dvInput.dirty || dvInput.touched)">
             
              <small class="p-error">
                <span *ngIf="rutInput.errors?.required || dvInput.errors.required">Campo obligatorio</span>
                <span *ngIf="rutInput.errors?.pattern">Campo inválido</span>            
              </small>
            </div>
          </div>

          <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6">
            <label>Código *</label>
            <input
              type="text"
              pInputText
              name="codigo"
              #codigo="ngModel"
              [(ngModel)]="busqueda.codigo"
              pattern="[0-9]+"
              maxlength="11"
              required
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />
            <div *ngIf="codigo.invalid && (codigo.dirty || codigo.touched)">
              <small class="p-error">
                <span *ngIf="codigo.errors.required">Campo obligatorio</span>
                <span *ngIf="codigo.errors.pattern">Campo inválido</span>
              </small>
            </div>
          </div>
        </div>
      </form>

      <div class="p-grid p-mt-3">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
          <div class="p-d-flex p-jc-center">
            <div>
              <app-captcha></app-captcha>
            </div>
          </div>
        </div>
      </div>

      <div class="p-grid p-mt-3">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-text-center">
          <p-button
            label="Limpiar"
            icon="fas fa-eraser"
            styleClass="p-mr-2 p-button-sm p-button-secondary p-button-outlined"
            (click)="limpiarBusqueda(); busquedaForm.reset()"
          ></p-button>          
          <p-button
            label="Buscar"
            icon="pi pi-search"
            styleClass="p-button-sm"
            (click)="
              busquedaForm.valid &&
                captchaComponent?.captchaForm?.controls?.recaptcha?.valid &&
                buscarAlumno()
            "
            [disabled]="
              !busquedaForm.valid ||
              !captchaComponent?.captchaForm?.controls?.recaptcha?.valid
            "
          ></p-button>
        </div>
      </div>
    </p-panel>
  </div>
</div>
