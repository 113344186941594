import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorD } from '../interfaces/error';
import { InitializationService } from './initialization.service';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import * as url from 'url';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable()
export class InvocaService {
    constructor(
        private _config: InitializationService,
        private http: HttpClient,
        public translateService: TranslateService,
        private injector: Injector
    ) {}

    async httpInvoke(serviceName: string, params?: any) {
        let arg =
            'arg=' +
            encodeURIComponent(JSON.stringify(params == null ? {} : params));

        return await this.http
            .post(`${environment.backend}/${serviceName}`, arg, {
                headers: this.getHeaders(),
            })
            .toPromise()
            .then((data) => {
                return this.resultMap(data);
            })
            .catch((error) => {
                this.sendSentry(
                    error,
                    `${environment.backend}`,
                    `${serviceName}`
                );
            });
    }

    async httpInvokeReport(serviceName: string, format: string, params?: any) {
        let arg =
            'arg=' +
            encodeURIComponent(JSON.stringify(params == null ? {} : params));

        return await this.http
            .post(`${environment.backend}/${serviceName}`, arg, {
                headers: this.getHeadersReport(format),
                responseType: 'blob' as 'blob',
            })
            .toPromise()
            .then(async (data) => {
                return await this.resultMapReport(
                    data,
                    format,
                    `${environment.backend}`,
                    serviceName
                );
            })
            .catch((error) => {
                this.sendSentry(
                    error,
                    `${environment.backend}`,
                    `${serviceName}`
                );
            });
    }

    async httpInvokeUrl(url: string, params?: any) {
        let arg =
            'arg=' +
            encodeURIComponent(JSON.stringify(params == null ? {} : params));
        return await this.http
            .post(`${url}`, arg, { headers: this.getHeaders() })
            .toPromise()
            .then((data) => {
                return this.resultMap(data);
            })
            .catch((error) => {
                throw error;
            });
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders()
            .set(
                'Content-Type',
                'application/x-www-form-urlencoded;charset=utf-8'
            )
            .set(
                'Authorization',
                window.sessionStorage.getItem('token') == null
                    ? ''
                    : window.sessionStorage.getItem('token')
            );
    }

    private getHeadersReport(format): HttpHeaders {
        let headers = this.getHeaders();
        headers.set('Accept', this._config.get('REPORT.TYPE')[format]);
        return headers;
    }

    private resultMap(response) {
        if (response.status == 'OK') return response.data;
        else throw response.error;
    }

    private async resultMapReport(response, format, serverUrl, reportUrl) {
        if (!response.type.includes('json')) {
            return new Blob([response], {
                type: `${
                    this._config.get('REPORT.TYPE')[format]
                }; charset=utf-8`,
            });
        } else {
            let fg = await this.translateService
                .get([`system_handler.report.text`], {
                    val: reportUrl,
                })
                .toPromise();
            this.sendSentry(
                new Error(fg[`system_handler.report.text`]),
                serverUrl,
                reportUrl
            );
        }
    }

    private sendSentry(error, serverUrl, backendUrl) {
        let errorDTIC: ErrorD = new ErrorD(
            error,
            serverUrl,
            backendUrl,
            url.parse(this.injector.get(Router).url).pathname
        );
        try {
            if (
                environment.production &&
                this._config.get('MONITORING_TRACING.SENTRY.ACTIVO') &&
                (errorDTIC.getContext().tipo == 'CRITICAL' ||
                    errorDTIC.getContext().tipo == 'FATAL') &&
                !errorDTIC.getContext().token
            ) {
                let userContext = {
                    nombre: JSON.parse(window.sessionStorage.getItem('user'))
                        .nombre_completo,
                    rut: JSON.parse(window.sessionStorage.getItem('user')).rut,
                };

                let scope = new Sentry.Scope();
                scope.setLevel(
                    errorDTIC.getContext().tipo == 'FATAL'
                        ? Sentry.Severity.Warning
                        : Sentry.Severity.Critical
                );
                scope.setTag('nivel', errorDTIC.getContext().nivel);
                scope.setTag('tipo', errorDTIC.getContext().tipo);
                scope.setContext('Cuenta UV', userContext);
                scope.setContext('Error Info', errorDTIC.getContext());
                Sentry.captureException(errorDTIC.getError(), scope);
            }
            throw errorDTIC;
        } catch (e) {
            throw errorDTIC;
        }
    }
}
