<div class="p-d-flex p-jc-center p-flex-column">
  <div class="p-text-center">
    <div class="error-icon"><i class="pi pi-times-circle"></i></div>
    <p class="error-title">
      {{ "system_handler.error.titulo" | translate }}
    </p>
    <p [innerHTML]="'system_handler.error.text' | translate"></p>
    <br />
  </div>
  <p-panel styleClass="p-panel-error">
    <ng-template pTemplate="header">
      {{ "system_handler.error.detalle" | translate }}
    </ng-template>
    <div style="line-break: anywhere">
      <ng-container *ngIf="data_error?.estado">
        <strong>{{ "system_handler.error.codigo" | translate }}: </strong>
        {{ data_error?.estado }}<br />
      </ng-container>
      <ng-container *ngIf="data_error?.nivel">
        <strong>{{ "system_handler.error.nivel" | translate }}: </strong>
        {{ data_error?.nivel }}<br />
      </ng-container>
      <ng-container *ngIf="data_error?.tipo">
        <strong>{{ "system_handler.error.type" | translate }}: </strong>
        {{ data_error?.tipo }}<br />
      </ng-container>
      <strong>URL: </strong> {{ data_error?.path }}<br />
      <br />
      <span [innerHTML]="data_error?.message"></span>
    </div>
  </p-panel>
</div>
