import { Injectable } from '@angular/core';
import { InvocaService } from '../../base/services/invoca.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class StartService {
    constructor(
        private invocaService: InvocaService,
        public translateService: TranslateService
    ) {}
    private output = null;

    async startServices() {
        try {
            if (this.output == null) {
                this.output = {};
                /**
                 * - Crear las funciones iniciales
                 * - Dentro de output:
                 *   - definir las variables iniciales
                 *   - asociar las variables a las funciones iniciales
                 */
            }
        } catch (e) {
            this.output = null;
            let fg = await this.translateService
                .get(['datosIniciales.error'])
                .toPromise();
            throw { error: e, msgs: fg['datosIniciales.error'] };
        }
    }

    public getOutput() {
        return this.output;
    }

    scrollTo(id) {
        setTimeout(() => {
            document.getElementById(id).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center',
            });
        }, 100);
    }
}
