import { Component, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
    selector: 'app-captcha',
    templateUrl: './captcha.component.html',
    styleUrls: ['./captcha.component.css'],
})
export class CaptchaComponent {
    constructor() {}

    @ViewChild('captchaElem') captcha: ReCaptcha2Component;
    captchaData = {
        key: environment.captcha,
        error: false,
    };
    public captchaForm: FormGroup;

    ngOnInit() {     
        
        /* if (window.sessionStorage.getItem("cc") != null && window.sessionStorage.getItem("rut") != null && window.sessionStorage.getItem("dv") != null) {

            this.captchaForm = new FormBuilder().group({
                recaptcha: new FormControl(null,null),
            });


        } else {

            this.captchaForm = new FormBuilder().group({
                recaptcha: new FormControl(null, [Validators.required]),
            });

        } */


        this.captchaForm = new FormBuilder().group({
            recaptcha: new FormControl(null, [Validators.required]),
        });



        
    }

  /*   webpay() {

        this.captchaForm = new FormBuilder().group({
            recaptcha: new FormControl(null,null),
        });

    } */

}
