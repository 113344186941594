import { Injectable } from '@angular/core';
import { InvocaService } from '../../base/services/invoca.service';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  constructor(
    private invokeService: InvocaService
  ) { }

  async getReporteComprobante(params?: any): Promise<any> {
    return this.invokeService.httpInvokeReport('getReporteFin700', 'pdf', params);
  }

}
