import { Component, ViewChild } from '@angular/core';
import { SystemService } from 'src/app/base/services/system.service';
import { ErroresHandler } from 'src/app/base/tools/handler/error.handler';
import { PagoCursosService } from '../../../services/pago-cursos.service';
import { environment } from '../../../../../environments/environment';
import { CaptchaComponent } from '../../../tools/components/captcha/captcha.component';
import { MessageService } from 'primeng/api';

import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';


@Component({
    selector: 'buscador',
    templateUrl: './buscador.component.html',
    styleUrls: ['./buscador.component.css'],
})
export class BuscadorComponent {
    @ViewChild(CaptchaComponent) captchaComponent!: CaptchaComponent;

    constructor(
        private erroresHandler: ErroresHandler,
        private systemService: SystemService,
        private pagoCursosService: PagoCursosService,
        private messageService: MessageService
    ) { }

    captchaKey = environment.captcha;
    busqueda: any = this.resetBusqueda();
    rut_c: any = '';
    siCursos: boolean = false;
    rutFin700 = '0';
    siRut: boolean = true;
    siSession: boolean = false;

    async ngOnInit() {

        if (window.sessionStorage.getItem("cc") != null && window.sessionStorage.getItem("rut") != null && window.sessionStorage.getItem("dv") != null) {

            this.busqueda.rut = window.sessionStorage.getItem("rut");
            this.busqueda.codigo = window.sessionStorage.getItem("cc");
            this.busqueda.dv = window.sessionStorage.getItem("dv");
            this.siSession = true; // para Captcha.

            window.sessionStorage.removeItem("rut");
            window.sessionStorage.removeItem("cc");
            window.sessionStorage.removeItem("dv");
          
            this.buscarAlumno();
        }
    }

    async buscarAlumno() {
        try {
            let permite = true;

            this.siCursos = false;

            let dv = this.getDV(this.busqueda.rut);

            if (this.busqueda.dv != dv) {
                permite = false;
                this.messageService.add({
                    key: 'main',
                    severity: 'warn',
                    detail: 'Rut ingresado no es válido. Intente nuevamente.',
                    summary: 'Buscar alumno',
                });

                this.siCursos = true;
              
                this.pagoCursosService.buscadorS.next({
                    sinCurso: this.siCursos,
                });  

                if (!this.siSession) {
                    this.captchaComponent.captcha.resetCaptcha();
                }

            } else {
                permite = true;
                this.systemService.setLoading(true);
                this.rutFin700 =
                    this.busqueda.rut.toString().padStart(10, '0') +
                    '-' +
                    this.busqueda.dv;
                let siCursos = await this.pagoCursosService.getValidaCursos(
                    this.rutFin700,
                    this.busqueda.codigo
                );

                this.systemService.setLoading(false);
                if (siCursos.curso == 'S') {
                    this.siCursos = false;
                    this.messageService.add({
                        key: 'main',
                        severity: 'success',
                        detail: 'Alumno tiene cursos registrados',
                        summary: 'Buscar alumno',
                    });

                    this.pagoCursosService.buscadorS.next({
                        rut: this.busqueda.rut,
                        dv: this.busqueda.dv,
                        codigo: this.busqueda.codigo,
                        rutFin700: this.rutFin700,
                        sinCurso: this.siCursos,
                        datos: true
                    });
                    if (!this.siSession) {
                        this.captchaComponent.captcha.resetCaptcha();
                    }
                } else {
                    this.siCursos = true;
                    if (!this.siSession) {
                        this.captchaComponent.captcha.resetCaptcha();
                    }


                    this.messageService.add({
                        key: 'main',
                        severity: 'warn',
                        detail: 'Alumno no tiene cursos registrados. Intente nuevamente.',
                        summary: 'Buscar alumno',
                    });

                    this.pagoCursosService.buscadorS.next({
                        rut: this.busqueda.rut,
                        dv: this.busqueda.dv,
                        codigo: this.busqueda.codigo,
                        rutFin700: '0',
                        sinCurso: this.siCursos,
                        datos: false
                    });
                }
            }

        } catch (e) {
            if (!this.siSession) {
                this.captchaComponent.captcha.resetCaptcha();
            }

            this.erroresHandler.processError(
                e,
                'alert',
                'Hubo un error al buscar al alumno. Intente nuevamente.',
                'Buscar alumno'
            );
        }

        // agregar finally con siSession = false

    }

    limpiarBusqueda() {       
        this.busqueda = this.resetBusqueda();

        if (!this.siSession) {            
            this.captchaComponent.captcha.resetCaptcha();
        }
       
    }

    private resetBusqueda() {
        this.pagoCursosService.buscadorS.next({                      
            datos: false
        });

        return {
            rut: '',
            dv: '',
            codigo: '',
        };
    }

    calculateDV() {

        if (this.busqueda.rut != '' && this.busqueda.dv != '') {

            let getDV = (T) => {
                var M = 0,
                    S = 1;
                for (; T; T = Math.floor(T / 10))
                    S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
                return S ? S - 1 : 'k';
            };
            let DV = getDV(this.busqueda.rut);

            if (DV == this.busqueda.dv) {
                this.siRut = true;
            } else {
                this.siRut = false;
            }
        }
    }

    getDV(T: any) {
        var M = 0,
            S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
        return S ? S - 1 : 'k';
    }
}
