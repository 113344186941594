
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/home/home.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';

//PRIMENG - No borrar
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

//PRIMENG - programador
import { PanelModule } from 'primeng/panel';

//OTROS
import { NgxCaptchaModule } from 'ngx-captcha';

//COMPONENTS
import { HomeMenuButtonsComponent } from '../base/components/home-menu-buttons/home-menu-buttons.component';
import { BuscadorComponent } from './components/home/buscador/buscador.component';
import { CursosComponent } from './components/home/cursos/cursos.component';
import { TablaCursosComponent } from './components/home/cursos/tabla-cursos/tabla-cursos.component';
import { CaptchaComponent } from './tools/components/captcha/captcha.component';
import { ModalReportesComponent } from './components/home/modal-reportes/modal-reportes.component';

@NgModule({
    declarations: [
        HomeComponent,
        HomeMenuButtonsComponent,
        BuscadorComponent,
        CursosComponent,
        TablaCursosComponent,
        CaptchaComponent,
        ModalReportesComponent,
    ],
    imports: [
        FormsModule,
        TranslateModule,
        CommonModule,
        ReactiveFormsModule,
        //PRIMENG
        ButtonModule,
        InputTextModule,
        TableModule,
        OverlayPanelModule,
        MenuModule,
        TooltipModule,
        PanelModule,
        //OTROS
        NgxCaptchaModule,
        DialogModule
    ],
})
export class ProjectModule {}
