import { Injectable } from '@angular/core';
import { InvocaService } from './invoca.service';
import { DateUtils } from '../tools/utils/date.utils';
import { Subject } from 'rxjs';
import { InitializationService } from './initialization.service';
import { CommonUtils } from '../tools/utils/common.utils';
import { environment } from '../../../environments/environment';

@Injectable()
export class PanelControlService {
    panelSubject = new Subject<object>();
    panel$ = this.panelSubject.asObservable();

    menusSubject = new Subject<object>();
    menus$ = this.menusSubject.asObservable();

    private menuHomeSubject = new Subject<object>();
    public menuHome$ = this.menuHomeSubject.asObservable();
    private menusHome = [];

    date_utils = new DateUtils();
    menus = [];
    items = [];

    constructor(
        private _config: InitializationService,
        private invoke: InvocaService,
        private commonUtils: CommonUtils
    ) {}

    async get_aplicaciones(params?: any): Promise<any> {
        let _apps = await this.invoke.httpInvoke(
            'base/getAplicaciones',
            params
        );

        let apps = [
                { grupo: 'Académicas administrativas', apps: [] },
                { grupo: 'Académicas docencia', apps: [] },
                { grupo: 'Administrativas', apps: [] },
                { grupo: 'Médicas', apps: [] },
            ],
            active = null;
        _apps.forEach((r) => {
            let _app = {
                nombre: r.nombre,
                id: r.id,
                tipo: r.tipoApp,
                grupo: r.grupoApp,
                icono: `${this._config.get(
                    'SISTEMA.URL.REPOSITORIO'
                )}${this._config.get('SISTEMA.URL.PATHAPPSIMG')}${r.imagen}`,
            };

            if (params.id_proyecto == _app.id) active = _app;

            if (_app.grupo == 'ACA-A') apps[0].apps.push(_app);
            if (_app.grupo == 'ACA-D') apps[1].apps.push(_app);
            if (_app.grupo == 'ADM') apps[2].apps.push(_app);
            if (_app.grupo == 'MED') apps[3].apps.push(_app);
        });

        return { apps: apps, active: active };
    }

    async get_modulos(params?: any): Promise<any> {
        let _mods = await this.invoke.httpInvoke('base/getModulos', params);

        let _modulos = [],
            sin_clasificacion = [],
            grupos = [],
            active = null,
            en_mantencion = false;
        _mods.forEach((r) => {
            if (r.nombre != 'Volver al Portal') {
                let _icon = '';
                switch (r.moduloActivo) {
                    case 1: // NORMAL
                        _icon = `${this._config.get(
                            'SISTEMA.URL.REPOSITORIO'
                        )}${this._config.get('SISTEMA.URL.PATHMODULOSIMG')}${
                            r.imagen
                        }.png`;
                        break;
                    case 2: // MANTENCION
                        _icon = `${this._config.get(
                            'SISTEMA.URL.REPOSITORIO'
                        )}${this._config.get(
                            'SISTEMA.URL.PATHMODULOSIMG'
                        )}maintenance.png`;
                        break;
                    case 3: // DESARROLLO
                        _icon = `${this._config.get(
                            'SISTEMA.URL.REPOSITORIO'
                        )}${this._config.get(
                            'SISTEMA.URL.PATHMODULOSIMG'
                        )}develop.png`;
                        break;
                }

                let json = {
                    nombre: r.nombre,
                    icono: _icon,
                    estilo: r.estilo,
                    cod_modulo: r.id,
                    url: r.url,
                    grupo: r.grupo,
                    estado: r.moduloActivo,
                    descripcion: r.descripcion,
                };

                if (json.grupo == null) sin_clasificacion.push(json);
                else if (!grupos.includes(json.grupo)) grupos.push(json.grupo);

                if (json.cod_modulo.toString() == params.codModulo.toString()) {
                    active = json;
                    en_mantencion = json.estado == 2;
                }

                _modulos.push(json);
            }
        });

        let modulos = [],
            active_alert = false;
        grupos.forEach((e) => {
            let tmp = [],
                grupo_activo = false;
            _modulos.forEach((f) => {
                if (e == f.grupo) tmp.push(f);
                if (f.cod_modulo == active.cod_modulo) {
                    grupo_activo = true;
                    active_alert = true;
                }
            });
            modulos.push({
                grupo: e.toUpperCase(),
                modulos: tmp,
                grupo_activo: grupo_activo,
            });
        });

        if (sin_clasificacion.length > 0) {
            modulos.push({
                grupo: 'OTROS',
                modulos: sin_clasificacion,
                grupo_activo: active_alert,
            });
        }

        return {
            modulos: modulos,
            active: active,
            en_mantencion: en_mantencion,
        };
    }

    async get_menus(params?: any): Promise<any> {
        let _menus = await this.invoke.httpInvoke('base/getMenus', params);

        let menus = [];

        _menus.forEach((r) => {
            if (r.metodo != 'home') {
                let _img = '',
                    _desc = '',
                    _font = '';

                _desc = r.descripcion;
                switch (r.menuActivo) {
                    case 1: // NORMAL
                        if (this._config.get('SISTEMA.MENUS.FLAT_ICONS')) {
                            _img = `${this._config.get(
                                'SISTEMA.URL.REPOSITORIO'
                            )}${this._config.get('SISTEMA.URL.PATHMENUSIMG')}${
                                r.iconoBootstrap
                            }.png`;
                            _font = 'fas fa-sign-in-alt';
                        } else {
                            _img = r.iconoBootstrap;
                        }
                        break;
                    case 2: // MANTENCION
                        if (this._config.get('SISTEMA.MENUS.FLAT_ICONS')) {
                            _img = `${this._config.get(
                                'SISTEMA.URL.REPOSITORIO'
                            )}${this._config.get(
                                'SISTEMA.URL.PATHMENUSIMG'
                            )}maintenance.png`;
                            _font = 'fas fa-cogs';
                        } else {
                            _img = 'fas fa-cogs';
                        }
                        break;
                    case 3: // DESARROLLO
                        if (this._config.get('SISTEMA.MENUS.FLAT_ICONS')) {
                            _img = `${this._config.get(
                                'SISTEMA.URL.REPOSITORIO'
                            )}${this._config.get(
                                'SISTEMA.URL.PATHMENUSIMG'
                            )}develop.png`;
                            _font = 'fas fa-code';
                        } else {
                            _img = 'fas fa-code';
                        }
                        break;
                }

                let json = {
                    estado: r.menuActivo,
                    nombre: r.nombre,
                    icono: _img,
                    font: _font,
                    estilo: true,
                    metodo: r.metodo == 'home' ? '' : r.metodo,
                    descripcion: _desc,
                    id: r.metodo != 'home' ? r.idMenu : 0,
                    items: [],
                    local: false,
                };

                this.menus.push(json);

                if (r.items.length > 0) {
                    r.items.forEach((m) => {
                        let i = '';
                        switch (m.itemActivo) {
                            case 1: // NORMAL
                                i = `${this._config.get(
                                    'SISTEMA.URL.REPOSITORIO'
                                )}${this._config.get(
                                    'SISTEMA.URL.PATHITEMSIMG'
                                )}${m.iconoBootstrap}.png`;
                                break;
                            case 2: // MANTENCION
                                i = `${this._config.get(
                                    'SISTEMA.URL.REPOSITORIO'
                                )}${this._config.get(
                                    'SISTEMA.URL.PATHMENUSIMG'
                                )}maintenance.png`;
                                break;
                            case 3: // DESARROLLO
                                i = `${this._config.get(
                                    'SISTEMA.URL.REPOSITORIO'
                                )}${this._config.get(
                                    'SISTEMA.URL.PATHMENUSIMG'
                                )}develop.png`;
                                break;
                        }

                        let _json = {
                            icono: i,
                            estado: m.itemActivo,
                            metodo: m.metodo,
                            nombre: m.nombre,
                            descripcion: m.descripcion,
                        };
                        this.items.push(_json);
                        json.items.push(_json);
                    });
                }

                menus.push(json);
            }
        });

        if (this._config.get('SISTEMA.HOME')) {
            this.menus.unshift(this._homeMenu());
            menus.unshift(this._homeMenu());
        }

        if (!environment.production && environment.hasOwnProperty('menus')) {
            try {
                for (let i = 0; i < environment['menus'].length; i++) {
                    let e = environment['menus'][i];
                    let index = -1;
                    let obj;
                    index = menus.findIndex(
                        (f) => f.metodo === e.metodo && e.metodo != ''
                    );
                    obj = {
                        estado: e.estado,
                        nombre: `${e.nombre} (Local)`,
                        icono: e.icono,
                        font:
                            e.estado == 1
                                ? 'fas fa-sign-in-alt'
                                : e.estado == 2
                                ? 'fas fa-cogs'
                                : 'fas fa-code',
                        estilo: true,
                        metodo: e.metodo,
                        descripcion: e.descripcion,
                        id: index !== -1 ? menus[index].id : menus.length + 1,
                        items: index !== -1 ? menus[index].items : [],
                        local: true,
                    };
                    if (index !== -1) menus[index] = obj;
                    else menus.push(obj);
                }
                this.menus = menus;
            } catch (e) {}
        }

        this.setMenusHome(menus);
        return menus;
    }

    private setMenusHome(menus) {
        if (menus.length > 0) {
            let tmp = this.commonUtils.mergeDeepArray(menus);
            this.menusHome = this.commonUtils.removeObjectsFromArray(
                tmp,
                'id',
                0
            );
        }
        this.menuHomeSubject.next({ menus: this.menusHome });
    }

    public getMenusHome() {
        let tmp = this.commonUtils.mergeDeepArray(this.menusHome);
        tmp.map((r) => (r.estilo = true));
        return tmp;
    }

    async get_fecha_actual(params?: any): Promise<any> {
        try {
            let fecha = await this.invoke.httpInvoke(
                'base/getFechaActual',
                params
            );
            let json = this.date_utils.getNumberToJSON(fecha.fechaActualNumber);
            return { json: json, date: this.date_utils.parseJSONtoDate(json) };
        } catch (e) {
            return null;
        }
    }

    _homeMenu() {
        let img = this._config.get('SISTEMA.MENUS.FLAT_ICONS')
            ? `${this._config.get('SISTEMA.URL.REPOSITORIO')}${this._config.get(
                  'SISTEMA.URL.PATHMENUSIMG'
              )}home.png`
            : 'fas fa-home';

        return {
            estado: 1,
            nombre: 'Inicio',
            icono: img,
            estilo: '',
            metodo: '',
            descripcion: 'Inicio',
            id: 0,
            items: [],
            grupo: '',
        };
    }

    _getBreadCrumb(url, extras) {
        let menu = this._homeMenu(),
            item = null;

        if (extras == null) {
            if (this.menus.length > 0) {
                menu = this.menus.find(function (e) {
                    return `/${e.metodo}` === url;
                });
                item = this.items.find(function (e) {
                    return `/${e.metodo}` === url;
                });
            }
        } else {
            menu = extras.hasOwnProperty('menu') ? extras.menu : null;
            item = extras.hasOwnProperty('item') ? extras.item : null;
        }

        let breadCrumb = [],
            position = '';

        if (this._config.get('SISTEMA.LANDING') != menu.metodo) {
            if (menu != null || item != null) {
                let metodo = item != null ? item.metodo : menu.metodo;
                position =
                    metodo != ''
                        ? `${menu.nombre}` +
                          (item != null ? ' > ' + item.nombre : '')
                        : '';
                if (metodo != '') {
                    breadCrumb = [{ label: menu.nombre, object: menu }];
                    if (item != null)
                        breadCrumb.push({ label: item.nombre, object: item });
                }
            }
        }

        return {
            position: position,
            breadCrumb: breadCrumb,
            menu: menu,
            item: item,
        };
    }

    navigateMenu(router, menu, item?) {
        let maintenance = false,
            devop = false;
        if (menu.estado == 2) maintenance = true;
        if (menu.estado == 3) devop = true;

        if (!devop && !maintenance && item != null) {
            if (item.estado == 2) maintenance = true;
            if (item.estado == 3) devop = true;
        }

        if (!devop) {
            if (!maintenance) {
                router.navigate(
                    [`/${menu.metodo}${item != null ? '/' + item.metodo : ''}`],
                    { state: { menu: menu, item: item } }
                );
            } else {
                router.navigate([`/maintenance`], {
                    state: { mode: 'menu', menu: menu, item: item },
                });
            }
        } else {
            router.navigate([`/develop`], {
                state: { menu: menu, item: item },
            });
        }
    }
}
