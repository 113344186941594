export const environment = {
    production: true,
    backend: 'https://controladorexterno.uv.cl/pagoCursos',
    sentry: {
        dns: 'https://80058c387b8044538992c7805d32459c@o560748.ingest.sentry.io/6402513',
        tracingOrigins: ['https://pagocursos.uv.cl'],
    },
    analytics: 'G-X28F8G12QP',
    captcha: '6LcmKiMgAAAAAP_i5FybtHpbNk2qHQUYPDk_d9OE',
};
